/*Modal.css*/

.modal {
    display: block;
    /* Show the modal */
    position: fixed;
    /* Fixed position */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 1000;
    /* Ensure it's above other content */
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the modal */
    padding: 40px;
    /* Increased padding */
    background: #E9DAC8;
    border: 4px solid #105618;
    /* Full green border */
    border-radius: 5px;
    /* Rounded corners */
    width: 50%;
    /* Larger width */
    max-width: 800px;
    /* Maximum width, adjust as needed */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* Optional: Adds shadow for depth */
    z-index: 1001;
    /* Ensure it's above the modal background */
    box-sizing: border-box;
    /* Ensures padding is included in the width */

}

.modal-content h2 {
    text-align: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

@media screen and (max-width: 700px) {

    /* Adjust 600px to your desired threshold */
    .modal {
        font-size: 0.7em;
        /* Adjust the size as needed */
    }
}

@media screen and (max-width: 500px) {

    /* Adjust 600px to your desired threshold */
    .modal {
        font-size: 0.5em;
        /* Adjust the size as needed */
    }
}

@media screen and (max-width: 400px) {

    /* Adjust 600px to your desired threshold */
    .modal {
        font-size: 0.35em;
        /* Adjust the size as needed */
    }
}