body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* streaming country button and dropdown */
.streaming-dropdown {
  position: relative;
  display: inline-block;
}

.country-list {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0 0;
  z-index: 10;
}

.country-list li {
  padding: 8px 16px;
  cursor: pointer;
}

.country-list li:hover {
  background-color: #f1f1f1;
}