/* App.css */

body {
  margin: 0;
  padding: 0;
  background-color: #FAF3E0;
  /* Neutral Light Beige */
  color: #333D40;
  /* Dark Charcoal */
}

.app {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.app-content {
  flex-grow: 1;
  /* Allow this container to grow and take up available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .app {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} */

.container {
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 20px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #5C9990;
  /* Muted Teal */
  background-color: #FAF3E0;
  /* Neutral Light Beige */
  color: #333D40;
  /* Dark Charcoal */
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 10px;
  border: none;
  background-color: #5C9990;
  /* Muted Teal */
  color: #FAF3E0;
  /* Neutral Light Beige */
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #333D40;
  /* Dark Charcoal */
  color: #FAF3E0;
  /* Neutral Light Beige */
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
}

.movie-card {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  min-width: 50%;
  max-width: 70%;
  background-color: #E9DAC8;
  /* Slightly Darker Beige */
  border-radius: 4px;
  padding: 10px;
}

.movie-poster {
  margin-right: 20px;
  max-width: 150px;
}

.movie-details {
  flex: 1;
  color: #2f5b30d4;
  /* Darker Green */
}

@media (max-width: 600px) {
  .movie-card {
    flex-direction: column;
    align-items: center;
  }

  .movie-poster {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.retry-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* Header content CSS */

/* Ensure the header doesn't collapse if it only contains floated elements */
.app-header {
  width: 95%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* Adjust as necessary */
}

.sliding-text {
  overflow: hidden;
  white-space: nowrap;
  /* Prevent text from breaking to the next line */
  position: relative;
  height: 30px;
  /* Adjust based on your requirements */
  width: 100%;
  /* Adjust based on the width you want for the sliding area */
}

.sliding-text span {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: #105618;
  margin-right: 50px;
  left: 0;
  position: absolute;
  animation: slideText 45s linear infinite;
  /* animation: none */
  /* Adjust 15s for speed */
}

@keyframes slideText {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
    /* Adjusted to -50% so the animation completes halfway */
  }
}




.fas.fa-share-alt {
  display: inline-block;
  padding: 8px 15px;
  /* Padding around the text */
  cursor: pointer;
  font-size: 24px;
  /* adjust size as needed */
}

.site-logo {
  display: block;
  max-width: 150px;
  /* Adjust based on desired maximum width */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0;
  /* Remove any default margins */
  padding: 10px 0;
  /* Some padding on top and bottom, if desired */
  border: none;
  /* Remove borders, if any */
  transition: opacity 0.3s ease;
  /* Smooth transition for hover effect */
}

.site-logo:hover {
  opacity: 0.8;
  /* Slight fade on hover for a subtle effect */
}

.site-logo:focus {
  outline: none;
  box-shadow: none;
}

/* End Header CSS Content */


.footer {
  font-size: 0.75rem;
  /* This makes the font size smaller */
  text-align: center;
  /* Centers the text */
  padding: 10px;
  /* Adds some padding */
  width: 100%;
  /* Makes sure it spans the entire width */
  color: #333D40
}

.footer-content {
  display: flex;
  align-items: center;
  /* This will vertically align the items if they have different heights */
  justify-content: center;
  /* This will put maximum space between the text and the donate button */
}

.donate-button {
  margin-left: 20px;
  transform: scale(0.8);
  /* Optional: to provide some spacing between the text and the button */
}

.donate-button input[type="image"] {
  border: none;
  outline: none;
  /* This will remove the border that might appear when the button is focused */
}


/* streaming dropdown css */




.country-list {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0 0;
  z-index: 10;
}

.country-list li {
  padding: 8px 16px;
  cursor: pointer;
}

.country-list li:hover {
  background-color: #f1f1f1;
}

.movie-title-text {
  margin-right: 10%;
}



.movie-title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* This ensures vertical centering if your title/details have more height */
}

.end-movie-remark {
  justify-content: center;
  align-items: center;
}

/* Dropdown bar css styling */

#menu {
  z-index: 2;
  position: relative;
}

#menu-bar {
  width: 45px;
  height: 40px;
  /* margin: 30px 0 20px 20px; */
  margin: 0 20px;

  cursor: pointer;
}

.bar {
  height: 5px;
  width: 100%;
  background-color: #105618;
  display: block;
  border-radius: 5px;
  transition: 0.3s ease;
}

#bar1 {
  transform: translateY(-4px);
}

#bar3 {
  transform: translateY(4px);
}

.nav {
  transition: 0.3s ease;
  display: none;
}

.nav ul {
  padding: 0 22px;
}

.nav li {
  list-style: none;
  padding: 12px 0;
}

.nav li a {
  color: #105618;
  font-size: 24px;
  /* font-weight: bold; */
  text-decoration: none;
}

.nav li a:hover {
  font-weight: bold;
}

.menu-bg {
  position: absolute;
  top: 0;
  left: 0;

}


.menu-bg {
  z-index: -1;
  width: 0;
  height: 0;
  margin: 30px 0 20px 20px;
  background: radial-gradient(circle, #E9DAC8, #E9DAC8);
  border-radius: 50%;
  transition: 0.3s ease;
}

.change {
  display: block;
}

.change .bar {
  background-color: #105618;
}

.change #bar1 {
  transform: translateY(4px) rotateZ(-45deg);
}

.change #bar2 {
  opacity: 0;
}

.change #bar3 {
  transform: translateY(-6px) rotateZ(45deg);
}

.change-bg {
  width: 520px;
  height: 460px;
  transform: translate(-60%, -30%);
}

/* ad css */

.ad-content-ad {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1000px) {
  .banner-class {
    position: fixed;
    /* or absolute */
    top: 25%;
    /* Adjust as needed */
    left: 0;
    width: 160px;
    /* Your ad width */
    height: 600px;
    /* Your ad height, or 100% if you want it full height */
    z-index: 10;
    /* Ensure it's above other content */
  }

  div[data-banner-id="470744"] {
    position: fixed;
    /* or absolute */
    top: 25%;
    /* Adjust as needed */
    right: 0;
    width: 160px;
    /* Your ad width */
    height: 600px;
    /* Your ad height, or 100% if you want it full height */
    z-index: 10;
    /* Ensure it's above other content */
  }
}

@media screen and (max-width: 1000px) {
  div[data-banner-id="470742"] {
    display: none;
  }

  div[data-banner-id="470744"] {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .banner-Left {
    position: fixed;
    /* or absolute */
    top: 25%;
    /* Adjust as needed */
    left: 0;
    width: 160px;
    /* Your ad width */
    height: 600px;
    /* Your ad height, or 100% if you want it full height */
    z-index: 10;
    /* Ensure it's above other content */
  }

  .banner-Right {
    position: fixed;
    /* or absolute */
    top: 25%;
    /* Adjust as needed */
    right: 0;
    width: 160px;
    /* Your ad width */
    height: 600px;
    /* Your ad height, or 100% if you want it full height */
    z-index: 10;
    /* Ensure it's above other content */
  }
}

@media screen and (max-width: 1000px) {
  .banner-Left {
    display: none;
  }

  .banner-Right {

    display: none;
  }

  .banner-mobile {
    position: fixed;
    /* or absolute */
    top: 25%;
    /* Adjust as needed */
    right: 50%-300px;
    width: 300px;
    /* Your ad width */
    height: 100px;
    /* Your ad height, or 100% if you want it full height */
    z-index: 10;
    /* Ensure it's above other content */
  }

}

@media screen and (min-width: 1000px) {
  .banner-mobile {
    display: none;

  }
}